import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';

import ReadMoreFormSectionWrapper from './readMoreForm.style'

const ReadMoreFormSection = ({
  title,
  titleText,
  description,
  descriptionText,
  htmlDescription,
  textArea,
  imageWrapper,
  rightText,
  btnStyle,
  image,
}) => {
  return (
    <ReadMoreFormSectionWrapper>
      <Container>
        <Box flexBox  flexWrap="wrap"
            justifyContent="center">
          <Box
            {...textArea}
          >
            <FeatureBlock
              title={<Heading content={titleText} {...title} />}
              description={<Text content={descriptionText} htmlContent={htmlDescription} {...description} mb={0} />}
            />
          </Box>
        </Box>
      </Container>
    </ReadMoreFormSectionWrapper>
  );
};

ReadMoreFormSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

ReadMoreFormSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '65%'],
  },
  imageWrapper: {
    boxShadow: 'none',
    width: '100%',
  },
  title: {
    fontSize: ['24px', '26px', '26px', '36px', '36px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%',],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#080808',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%',],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default ReadMoreFormSection;

import React  from 'react';
import Text from 'reusecore/src/elements/Text';
import Box from 'reusecore/src/elements/Box';
import ImageList from '../../../components/ImageList';
import Container from '../../../components/UI/Container';


import CorriereLogo from "../../../assets/image/saas/corriere-della-sera-logo.jpg";
import PagamentiDigitaliLogo from "../../../assets/image/saas/pagamenti-digitali-logo.gif";
import ItaliaOggiLogo from "../../../assets/image/saas/italia-oggi-logo.png";

const InTheNewsSection = ({
  row,
  col,
}) => {
  return (
    <Container>
    <Box className="row" {...row}>
      <Box
        className="col"
        {...col}
        flexBox={true}
        flexWrap="wrap"
        alignItems="center"
      >
      <Text
        content="Parlano di noi:"
        mb={1}
        color="#343d48cc"
      />
      <ImageList images={[
        {
          src: CorriereLogo,
          width: '180px',
          href: 'https://milano.corriere.it/notizie/cronaca/20_agosto_16/onda-startup-innovativecosi-sfidiamo-crisi-covid-e8c52b3e-decc-11ea-a8ef-59f191bcf6be.shtml'
        },
        {
          src: ItaliaOggiLogo,
          width: '120px',
          href: 'https://www.italiaoggi.it/news/pos-e-concorrenza-sui-costi-2475650'
        },
        {
          src: PagamentiDigitaliLogo,
          width: '200px',
          href: 'https://www.pagamentidigitali.it/payment-innovation/pos-ecco-quanto-costa/'
        },
      ]} />
      </Box>
    </Box>
    </Container>
  );
};

InTheNewsSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    justifyContent: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '100%', '75%', '65%']
  },
}

export default InTheNewsSection;

import styled from 'styled-components';

const ReadMoreFormSectionWrapper = styled.section`
  &:after {
    content: '';
    position: absolute;
    left: 38%;
    right: 38%;
    bottom: 0;
    height: 1px;
    background: #dadada;
  }
  padding-bottom: 70px;
  margin-bottom: 50px;
  @media (max-width: 990px) {
    padding-bottom: 60px;
  }
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
`;

export default ReadMoreFormSectionWrapper;

import styled from 'styled-components';
import { themeGet } from 'styled-system';
import BannerBG from '../../../assets/image/saas/saas-banner.jpg';

export const InputWrapper = styled.div`
  display: ${props => (props.half ? 'flex' : 'block')};
  ${props => (props.hidden ? 'display: none' : '')};
  margin-left: ${props => (props.ml ? props.ml : 0)};
  ${props => props.half && 'justify-content: space-between;'}
  p {
    flex-grow: 0;
    flex-basis: 40%;
  }
  @media screen and (min-width: 767px) {
    display: ${props => (props.flex || props.half ? 'flex' : 'block')};
    ${props => (props.hidden ? 'display: none' : '')};
  }
  align-items: center;
  justify-items: center;
  margin-bottom: 5px;
  .reusecore__select {
    flex-grow: 1;
    .select__control {
      box-shadow: 0 3px 20px rgba(35, 49, 90, 0.08);
      height: 50px;
      @media screen and (max-width: 1023px) {
        height: 45px;
      }
      border-radius: 5px;
      &.select__control--is-focused {
        border-color: ${themeGet('colors.primary')};
      }
    }
    .select__value-container {
      padding-left: 21px;
    }
    .select__placeholder,
    input,
    .select__single-value {
      /* font-size: 18px; */
      @media screen and (max-width: 1023px) {
        font-size: 16px;
      }
    }
  }
  .reusecore__input {
    ${props => !props.half && 'flex-grow: 1;'}
    &.icon-right {
      .field-wrapper {
        input {
          padding-right: 80px;
        }
      }
    }
    .field-wrapper {
      input {
        /* border: 0; */
        border-radius: 5px;
        height: 50px;
        @media screen and (max-width: 1023px) {
          height: 45px;
        }
        box-shadow: 0 3px 20px rgba(35, 49, 90, 0.08);
        color: #20201d;
        font-size: 16px;
        font-weight: 400;
        padding-left: 21px;

        padding-top: 0;
        padding-bottom: 0;
        &:placholder {
          color: rgba(32, 32, 29, 0.5);
        }

        ${props => props.error && `border-color: red;`}
      }
      @media screen and (max-width: 1023px) {
        input {
          font-size: 16px;
          &[type='email'] {
            font-size: 15px;
          }
        }
      }
      .input-icon {
        width: 80px;
        height: 100%;
        > div {
          svg {
            width: 28px;
            height: 28px;
            path {
              fill: #20201d;
            }
          }
        }
      }
    }
  }

  .reusecore__radio {
    .reusecore__field-label {
      font-size: 18px;
    }
    > label > div {
      ${props => props.error && `border-color: red;`}
    }
  }

  .reusecore__checkbox {
    input[type='checkbox'] + div {
      ${props => props.error && `border-color: red;`}
    }
  }

  .reusecore__input {
    ${props => props.half && 'width: 48%;'}
  }
`;

const BannerWrapper = styled.section`
  &:after {
    content: '';
    position: absolute;
    left: 38%;
    right: 38%;
    bottom: 0;
    height: 1px;
    background: #dadada;
  }
  padding-top: 120px;
  padding-bottom: 70px;
  margin-bottom: 50px;
  background-image: url(${BannerBG});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* min-height: 100vh; */
  position: relative;
  overflow: hidden;
  @media (max-width: 990px) {
    padding-top: 180px;
    padding-bottom: 60px;
    min-height: auto;
  }
  @media (max-width: 767px) {
    padding-top: 130px;
    padding-bottom: 40px;
    min-height: auto;
  }

  @media only screen and (max-width: 480px) {
    background: none;
  }

  .particle {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    @media (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .row {
    position: relative;
    z-index: 1;
  }

  .button__wrapper {
    margin-top: 40px;
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    .reusecore__button {
      &.outlined {
        border-color: rgba(82, 104, 219, 0.2);
      }
    }
  }
`;

export const BannerObject = styled.div`
  position: absolute;
  width: 45%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    display: none;
  }
  .objectWrapper {
    margin-left: auto;
    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 0;
      right: 0;
      .chatObject {
        position: absolute;
        top: 20px;
        left: 120px;
      }
    }
  }
`;

export const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  border: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
  padding: 7px 25px;
  box-shadow: 0px 7px 25px 0px rgba(22, 53, 76, 0.05);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
`;

export const InputError = styled.div`
  color: red;
`;

export default BannerWrapper;

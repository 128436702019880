import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper
} from 'common/src/containers/Saas/saas.style';
import Navbar from 'common/src/containers/Saas/Navbar';
import UserFormSection from 'common/src/containers/Saas/UserFormSection';
import ReadMoreFormSection from 'common/src/containers/Saas/ReadMoreFormSection';
import InTheNewsSection from 'common/src/containers/Saas/InTheNewsSection';
import Footer from 'common/src/containers/Saas/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO
          title="SignorPOS | Completa con i tuoi dati"
          description="SignorPOS, per trovare il POS più economico e con le migliori condizioni contrattuali"
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar button={false} />
            </DrawerProvider>
          </Sticky>
          <UserFormSection />
          <ReadMoreFormSection
            titleText="Costo POS in negozio: calcola il preventivo"
            htmlDescription="<strong>POS troppo caro</strong>? Tariffe troppo complicate? Calcola il tuo preventivo e risparmia sul POS grazie al servizio di confronto POS di SignorPOS.it. Compila il form con i tuoi dati e il transato che ti aspetti in un anno.<br /><br /><b>Non conosci il tuo transato?</b> Non preoccuparti, avrai modo di cambiarlo successivamente! <strong>Confronta le offerte POS</strong> di banche ed istituti di pagamento e scegli quello più adatto alle tue esigenze e al tuo utilizzo. Ti basteranno pochi clic per risparmiare fino a 9.000€ all'anno sull'accettazione di pagamenti con carta! Prova subito il servizio di comparazione: è facile, veloce e gratuito!"
          />
          <InTheNewsSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
